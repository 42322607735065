import st from "./TabBar.module.scss";

import game from "./images/game.png";
import app from "./images/app.png";
import movie from "./images/movie.png";
import book from "./images/book.png";
import ertong from "./images/ertong.png";

const TabBar = (props) => {
    const {downLoadApp} = props;

    const clickHandler = () => {
        downLoadApp && downLoadApp();
    };

    const tabList = [
        {
            img: game,
            title: "Jogos",
        },
        {
            img: app,
            title: "Aplicativas",
        },
        {
            img: movie,
            title: "Filmes",
        },
        {
            img: book,
            title: "Livros",
        },
        {
            img: ertong,
            title: "CrianÃ§as",
        },
    ];
    return (
        <div className={st.container}>
            {tabList.map((v, key) => {
                return (
                    <div key={key} className={st.tabItem} onClick={clickHandler}>
                        <img src={v.img} alt="" />
                        <div style={key === 1 ? {color: "#01875f"} : {}}>{v.title}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default TabBar;
