import st from "./Header.module.scss";

import googleIcon from "./images/google-icon.svg";
import search from "./images/search.png";
import question from "./images/question.png";
import google from "./images/google.png";

const Header = (props) => {
    const {downLoadApp} = props;

    const clickHandler = () => {
        downLoadApp && downLoadApp();
    };

    const menuList = ["Jogos", "Aplicativas", "Filmes", "Livros", "CrianÃ§as"];

    return (
        <div className={st.container}>
            <div className={st.containerTop}>
                <div className={st.containerTopL}>
                    <img src={googleIcon} alt="" className={st.googleIcon} />
                    <div className={st.containerTopLTitle}>Google Play</div>
                    <div className={st.containerTopLMenus}>
                        {menuList.map((v, key) => {
                            return (
                                <div key={key} className={st.containerTopLMenuItem} style={key === 1 ? {color: "#01875f"} : {}} onClick={clickHandler}>
                                    {v}
                                    {key === 1 && <div className={st.itemBottom}></div>}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={st.containerTopR}>
                    <div className={st.containerTopRItem} onClick={clickHandler}>
                        <img src={search} alt="" className={st.itemIcon} />
                    </div>
                    <div className={st.containerTopRItem} onClick={clickHandler}>
                        <img src={question} alt="" className={st.itemIcon} />
                    </div>
                    <div className={st.containerTopRItem} onClick={clickHandler}>
                        <img src={google} alt="" className={st.googleIcon} />
                    </div>
                </div>
            </div>
            <div className={st.containerTopMenus}>
                {menuList.map((v, key) => {
                    return (
                        <div key={key} className={st.containerTopMenuItem} style={key === 1 ? {color: "#01875f"} : {}} onClick={clickHandler}>
                            {v}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Header;
